import { ReactElement, ReactNode } from 'react';
import classNames from 'classnames';
import { Container } from 'reactstrap';

import Background from './Background';

const Section = ({
  fluid = false,
  bg,
  image,
  overlay,
  position,
  bgClassName,
  className,
  children,
}: {
  fluid?: boolean;
  bg?: string;
  image?: string;
  overlay?: boolean | string;
  position?: string | { x: string; y: string };
  bgClassName?: string;
  className?: string;
  children: ReactNode;
}): ReactElement => (
  <section className={classNames({ [`bg-${bg}`]: bg }, className)}>
    {image && (
      <Background image={image} overlay={overlay} position={position} className={bgClassName} />
    )}
    <Container fluid={fluid}>{children}</Container>
  </section>
);

export default Section;
