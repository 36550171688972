import { ReactElement, createElement, ReactNode, CSSProperties } from 'react';
import classNames from 'classnames';

const Flex = ({
  justify,
  align,
  inline = false,
  column = false,
  wrap = false,
  className = '',
  style = {},
  tag = 'div',
  children,
}: {
  justify?: 'start' | 'center' | 'end' | 'between' | 'around';
  align?: 'start' | 'center' | 'end' | 'stretch';
  inline?: boolean;
  column?: boolean;
  wrap?: boolean;
  className?: string;
  tag?: string;
  children: ReactNode;
  style?: CSSProperties;
}): ReactElement =>
  createElement(
    tag,
    {
      className: classNames(
        {
          'd-flex': !inline,
          'd-inline-flex': inline,
          [`justify-content-${justify}`]: justify,
          [`align-items-${align}`]: align,
          'flex-column': column,
          'flex-wrap': wrap,
        },
        className,
      ),
      style,
    },
    children,
  );

export default Flex;
