import { ReactElement } from 'react';
import { ApolloClient, ApolloProvider } from '@apollo/client';
import { getDataFromTree } from '@apollo/client/react/ssr';
import { NextComponentType, NextPageContext } from 'next';
import { pick } from 'lodash';
import { stringify } from 'querystring';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import jwtDecode from 'jwt-decode';
import { isPast } from 'date-fns';

import initApollo from './init-apollo';
import { ZenvestContext, getZenvestContext } from '../../../../lib/server-utils';

type Props = {
  zenvestContext: ZenvestContext;
  apolloClient?: ApolloClient<NormalizedCacheObject>;
  apolloState?: NormalizedCacheObject;
};

type WithApolloReturnType = {
  ({ zenvestContext, apolloClient, apolloState, ...pageProps }: Props): ReactElement;
  displayName: string;
  getInitialProps(context: NextPageContext): Promise<Props>;
};

const isAuthorized = ({ authorization }: ZenvestContext) => {
  if (!authorization) {
    return false;
  }
  if (authorization.startsWith('Basic')) {
    return true;
  }
  const [, token] = authorization.split(' ');
  const { exp } = jwtDecode(token) as { exp: number };
  return !isPast(exp * 1000);
};

const WithData = (PageComponent: NextComponentType, { ssr = true } = {}): WithApolloReturnType => {
  const WithApollo = ({
    zenvestContext,
    apolloClient,
    apolloState,
    ...pageProps
  }: Props): ReactElement => (
    <ApolloProvider client={apolloClient ?? initApollo(zenvestContext, apolloState)}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <PageComponent {...pageProps} />
    </ApolloProvider>
  );
  // Set the correct displayName in development
  if (process.env.NODE_ENV !== 'production') {
    const displayName = PageComponent.displayName ?? PageComponent.name ?? 'Component';
    if (displayName === 'App') {
      console.warn('This withApollo HOC only works with PageComponents.');
    }
    WithApollo.displayName = `withApollo(${displayName})`;
  }
  if (ssr || PageComponent.getInitialProps) {
    WithApollo.getInitialProps = async (context: NextPageContext): Promise<Props> => {
      const { AppTree } = context;
      const zenvestContext = await getZenvestContext(
        context.req,
        context.res,
        context.query.organizationShortId as string,
      );
      // Initialize ApolloClient, add it to the ctx object so
      // we can use it in `PageComponent.getInitialProp`.
      const apolloClient = initApollo(zenvestContext);
      Object.assign(context, { apolloClient });
      Object.assign(context.query, { organizationShortId: zenvestContext.organizationShortId });
      // Run wrapped getInitialProps methods
      const pageProps = PageComponent.getInitialProps
        ? await PageComponent.getInitialProps(context)
        : {};
      // Only on the server:
      if (context.req && context.res) {
        const { url = '/' } = context.req;
        const publicRoute =
          url.startsWith('/login') || url.startsWith('/signup') || url.startsWith('/error');
        if (!publicRoute && !isAuthorized(zenvestContext)) {
          const parsedUrl = new URL(`${process.env.INVESTOR_FRONTEND_URL}${url}`);
          const query = Object.fromEntries(parsedUrl.searchParams.entries());
          parsedUrl.searchParams.delete('email');
          context.res
            .writeHead(302, {
              Location: `${process.env.INVESTOR_FRONTEND_URL}/login?${stringify({
                ...pick(query, 'email', 'roleId'),
                redirectTo: parsedUrl.toString(),
              })}`,
            })
            .end();
        }
        // When redirecting, the response is finished.
        // No point in continuing to render
        if (context.res.writableEnded) {
          return { ...pageProps, zenvestContext };
        }
        // Only if ssr is enabled
        if (ssr) {
          try {
            // Run all GraphQL queries
            await getDataFromTree(
              <AppTree
                pageProps={{
                  ...pageProps,
                  apolloClient,
                  zenvestContext,
                }}
              />,
              { router: pick(context, 'asPath', 'pathname', 'query') },
            );
          } catch (error) {
            // Prevent Apollo Client GraphQL errors from crashing SSR.
            console.error('Error while running `getDataFromTree`', error);
          }
        }
      }
      return {
        ...pageProps,
        // Extract query data from the Apollo store
        apolloState: apolloClient.cache.extract(),
        zenvestContext,
      };
    };
  }
  return WithApollo;
};

export default WithData;
