import { IncomingMessage, ServerResponse } from 'http';
import { parse } from 'cookie';
import { getSession } from '@auth0/nextjs-auth0';

const getCredentials = (req: IncomingMessage): string => {
  const { credentials = '' } = parse(req.headers.cookie || '');
  return credentials;
};

const getAuthorization = async (req: IncomingMessage, res: ServerResponse) => {
  const credentials = getCredentials(req);
  if (credentials) {
    return `Basic ${credentials}`;
  }
  const session = await getSession(req, res);
  if (session?.accessToken) {
    return `Bearer ${session?.accessToken}`;
  }
  return '';
};

const getRoleId = (req: IncomingMessage): string => {
  const { roleId = '' } = parse(req.headers.cookie || '');
  return roleId;
};

export type ZenvestContext = {
  authorization: string;
  organizationShortId: string;
  roleId: string;
};

export const getZenvestContext = async (
  req?: IncomingMessage,
  res?: ServerResponse,
  organizationShortId = 'me',
): Promise<ZenvestContext> => {
  if (!req || !res) {
    return { authorization: '', organizationShortId, roleId: '' };
  }
  return {
    authorization: await getAuthorization(req, res),
    organizationShortId,
    roleId: getRoleId(req),
  };
};
