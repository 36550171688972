import { CSSProperties, ReactElement } from 'react';
import classNames from 'classnames';

const Background = ({
  image,
  overlay,
  position,
  className,
  style = {},
}: {
  image?: string;
  overlay?: boolean | string;
  position?: string | { x: string; y: string };
  className?: string;
  style?: CSSProperties;
}): ReactElement => {
  const bgStyle = { backgroundImage: image ? `url(${image})` : undefined, ...style };
  if (typeof position === 'string') {
    bgStyle.backgroundPosition = position;
  } else if (typeof position === 'object') {
    bgStyle.backgroundPositionX = position.x;
    bgStyle.backgroundPositionY = position.y;
  }
  return (
    <div
      className={classNames(
        'bg-holder',
        { overlay, [`overlay-${overlay}`]: typeof overlay === 'string' },
        className,
      )}
      style={bgStyle}
    />
  );
};

export default Background;
