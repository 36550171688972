import { ReactElement, ReactNode } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';

import Background from './Background';
import Flex from './Flex';
import Section from './Section';

const AuthCardLayout = ({
  leftSideContent,
  children,
}: {
  leftSideContent: ReactNode;
  children: ReactNode;
}): ReactElement => (
  <Section fluid className="py-0">
    <Background
      image="/img/login-background.png"
      className="bg-auth-card-shape"
      // style={{background: '#08131f'}}
    />
    <Row noGutters className="min-vh-100 flex-center">
      <Col lg={8} className="py-3">
        {/* <img
          className="bg-auth-circle-shape"
          src="/img/illustrations/bg-shape.png"
          alt="bg-shape"
        />
        <img
          className="bg-auth-circle-shape-2"
          src="/img/illustrations/shape-1.png"
          alt="shape-1"
        /> */}
        <Card className="overflow-hidden z-index-1">
          <CardBody className="p-0">
            <Row noGutters className="h-100">
              <Col md={5} className="text-white text-center bg-card-gradient">
                <div className="position-relative p-4 pt-md-5 pb-md-7">
                  <Background
                    image="/img/illustrations/half-circle.png"
                    className="bg-auth-card-shape"
                  />
                  <div className="z-index-1 position-relative">
                    <a
                      className="text-white mb-4 text-sans-serif font-weight-extra-bold fs-4 d-inline-block"
                      href="https://www.zenvest.com"
                    >
                      zenvest
                    </a>
                    <p className="text-100">
                      Welcome to Zenvest private investments management platform!
                    </p>
                  </div>
                </div>
                <div className="mt-3 mb-4 mt-md-4 mb-md-5">{leftSideContent}</div>
              </Col>
              <Col md={7} tag={Flex} align="center" justify="center">
                <div className="p-4 p-md-5 flex-grow-1">{children}</div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
    <style jsx>
      {`
        .bg-auth-circle-shape {
          width: 250px;
        }
        .bg-auth-circle-shape-2 {
          width: 150px;
        }
      `}
    </style>
  </Section>
);

export default AuthCardLayout;
