import { ApolloClient } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { setContext } from '@apollo/client/link/context';
import { InMemoryCache, NormalizedCacheObject } from '@apollo/client/cache';
import { uniqBy } from 'lodash';

import { ZenvestContext } from '../../../../lib/server-utils';

const create = (
  zenvestContext: ZenvestContext,
  initialState: NormalizedCacheObject,
): ApolloClient<NormalizedCacheObject> => {
  const httpLink = new BatchHttpLink({ uri: `${process.env.BACKEND_URL}/graphql` });
  const middlewareLink = setContext(
    (_, { headers = { origin: process.env.INVESTOR_FRONTEND_URL } }) => {
      if (zenvestContext.authorization) {
        Object.assign(headers, { authorization: zenvestContext.authorization });
      }
      Object.assign(headers, { 'zenvest-context': JSON.stringify(zenvestContext) });
      return { headers };
    },
  );

  // const fragmentMatcher = new IntrospectionFragmentMatcher({
  //   introspectionQueryResultData: {
  //     __schema: {
  //       types: [],
  //     },
  //   },
  // });

  return new ApolloClient({
    connectToDevTools: typeof window !== 'undefined',
    // Disables forceFetch on the server (so queries are only run once)
    ssrMode: typeof window === 'undefined',
    link: middlewareLink.concat(httpLink),
    cache: new InMemoryCache({
      typePolicies: {
        Ticket: {
          keyFields: false,
        },
        Query: {
          fields: {
            investorInbox: {
              keyArgs: ['filters'],
              merge(existing, incoming) {
                if (!existing) {
                  return incoming;
                }
                return {
                  ...incoming,
                  notifications: uniqBy(
                    [...existing.notifications, ...incoming.notifications],
                    '__ref',
                  ),
                };
              },
            },
            investorActivityLog: {
              keyArgs: ['filters'],
              merge(existing, incoming) {
                if (!existing) {
                  return incoming;
                }
                return {
                  ...incoming,
                  activities: uniqBy([...existing.activities, ...incoming.activities], '__ref'),
                };
              },
            },
          },
        },
      },
      // fragmentMatcher,
    }).restore(initialState),
    defaultOptions: {
      watchQuery: {
        // fetchPolicy: 'cache-and-network',
        errorPolicy: 'all',
      },
      query: {
        // fetchPolicy: 'cache-and-network',
        errorPolicy: 'all',
      },
      mutate: {
        errorPolicy: 'all',
      },
    },
  });
};

let apolloClient: ApolloClient<NormalizedCacheObject>;

const initApollo = (
  zenvestContext: ZenvestContext,
  initialState: NormalizedCacheObject = {},
): ApolloClient<NormalizedCacheObject> => {
  if (typeof window === 'undefined') {
    return create(zenvestContext, initialState);
  }
  if (!apolloClient) {
    apolloClient = create(zenvestContext, initialState);
  }
  return apolloClient;
};

export default initApollo;
